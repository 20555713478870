<template>

  <!-- son los objetos que se arrastran -->
  
  <div class="mx-auto btn-drag"
    draggable
    @dragstart.self="onDrag"
    @dragover.prevent
    @dragenter.prevent >
    <slot/>
  </div>
</template>

<script>
export default {
  name:"AppDrag",
  props: {
    transferData: {
      type: Object,
      required: true
    },
  },
  methods: {
    onDrag (e) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'

      e.dataTransfer.setData('payload', JSON.stringify(this.transferData))

    }
  }
}
</script>

<style lang="scss" scoped>
.btn-drag{
  color:#0E69B0;
  border: solid 1px #E4E4E4;
  border-radius: 10px;
}
</style>
