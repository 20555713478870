<template>
  <div 
    @mouseover="isHovering = true" 
    @mouseout="isHovering = false" 
    :class="{ 'Control-Option':isHovering }" class="pa-1">
    <v-system-bar v-show="isHovering">
      <v-spacer></v-spacer>
      <v-icon @click="controlDelete">fas fa-trash-alt</v-icon>
      <v-icon @click="controlChange">fas fa-cogs</v-icon>
    </v-system-bar> 
    <slot />
  </div>
</template>

<script>
export default {
  name:"controlOption",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      isHovering: false
    }
  },
  methods: {
    controlDelete() {
      this.$store.commit('DELETE_CAMPO', this.camposIndex)
    },
    controlChange() {
      this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:this.campos.NombreTipo+'Config' , campos:this.campos, camposIndex:this.camposIndex})
    }
  },
    
}
</script>

<style lang="css" >
.Control-Option {
    /* position: absolute; */
    /* top: 0; */
    /* left: 97%; */
    border: 1px solid #bdc3c7;
    background-color:white;;
}


</style>