<template>
<v-layout align-center justify-center row fill-height :class="{'mb-7': cargado }">
  <v-row no-gutters align="center" justify="center" class="mb-0">
    
    <v-col cols="12" v-if="!nuevo && !cargado" class="text-center under-sec-nomargin pa-2">
      <div class="text-left ntitlesdark--text text-min"><span>Crear nuevo</span></div>
      <v-btn
        small
        color="btndest"
        class="white--text mx-auto" 
        @click="nuevoForm">
        Nuevo
        <v-icon right dark small>
          fas fa-file-medical
        </v-icon>
      </v-btn>
    </v-col>
    <v-col cols="3" justify="start" v-if="!nuevo && cargado">
      <v-btn
        small
        color="error"
        class="white--text " 
        @click="CancelForm">
        Cancelar
        <v-icon right dark small>
          fas fa-times
        </v-icon>
      </v-btn>
    </v-col> 
    <!-- CARGAR EXISTENTE -->
    <v-row no-gutters align="center" justify="center" class="mb-0 under-sec-nomargin pa-2 mt-3" v-if="!nuevo && !cargado">
      <v-col cols="12" class="mb-2">
        <div class="text-left ntitlesdark--text text-min"><span>Cargar existente</span></div>
      </v-col>
      <v-col cols="9" class="ma-0 pa-0">
        <v-select
          v-model="formulario"
          :items="aFormularios"
          item-text="NombreFormulario"
          item-value="idFD_Formulario"
          class=""
          clearable
          solo
          :label="isSubFormulario ? 'Seleccione un SubFormulario' : 'Seleccione un Formulario'"
          @change="changeFormulario"
        ></v-select>
      </v-col>
      <v-col cols="3" class="ma-0 pa-0 text-center">
          <v-btn
            small
            :disabled="formulario == ''"
            color="btndest"
            class=" white--text"
            @click="Cargar">
            Cargar
            <v-icon right dark small>
              fas fa-file
            </v-icon>
          </v-btn>
      </v-col>
    </v-row>

    <v-col cols="12"  v-if="nuevo">
      <v-text-field
        v-model="newFormName"
        background-color="white"
        class="mt-3 mx-2"
        label="Nuevo Formulario"
        :placeholder="isSubFormulario ? 'Escribe el nombre del SubFormulario' : 'Escribe el nombre del formulario'"
        outlined
      ></v-text-field>
    </v-col>

    <v-col cols="3" justify="end" v-if="nuevo" >
      <v-btn
        small
        color="error"
        class=" mx-2 white--text " 
        @click="cancelar">
        Cancelar.
        <v-icon right dark small>
          fas fa-times
        </v-icon>
      </v-btn>
    </v-col>  
     <v-col cols="3" justify="end" v-if="cargado||nuevo">
        <v-btn
          small
          color="success"
          class=" mx-3 white--text " 
          @click="guardar">
          Guardar
          <v-icon right dark small>
            fas fa-file-upload
          </v-icon>
        </v-btn>
    </v-col>
  </v-row> 
</v-layout>   
</template>

<script>
import axiosServices from '@/services/axiosServices.js'

export default {
  name:"FormControl",
  props: {
    value : {
      type:String
    },
    isSubFormulario : {
      type: Boolean,
      default: false    // Se indica que es formulario  por defector
    },
    isCancelar: {
       type: Boolean,
      default: null    // Se indica para valdiar evento de pulsacion de cancelar formulario
    }
  },
  data() {
    return {
      nuevo: false,
      aFormularios: [],
      formulario: '',
      cargado:false,
      newFormName: '',
      auxFormulario: {}
    }
  },
  mounted () {
    this.getFormularios()
  },
  methods: {
    async getFormularios() {
      this.aFormularios = []
      const sParams = !this.isSubFormulario ? '' : 'subForm=true'
      const axiosFormularios = await axiosServices.getConfiguration('buildDinamicos/formularios', true, sParams)
      this.aFormularios = axiosFormularios.aData

    },
    changeFormulario(){
      // const auxFormulario =  this.aFormularios.find( form => form.idFD_Formulario == this.formulario)
      this.auxFormulario =  this.aFormularios.find( form => form.idFD_Formulario == this.formulario)

      // this.$emit('input', auxFormulario == undefined ? '' : auxFormulario.NombreFormulario)
    },
    async guardar(){

      const sParams = !this.isSubFormulario ? '' : 'subForm=true'

      if(this.nuevo){
        const oBody = { 
          'Formulario' : this.newFormName
        }
        const axiosPostFormulario =  await axiosServices.postConfiguration('buildDinamicos/formularios', oBody, false, sParams)

        this.getFormularios()
        this.formulario = axiosPostFormulario.aData.idFD_Formulario
        //console.log(axiosPostFormulario)
        // this.$emit('input',axiosPostFormulario.aData.NombreFormulario)
        this.auxFormulario.NombreFormulario = axiosPostFormulario.aData.NombreFormulario
        this.auxFormulario.idFD_Formulario = axiosPostFormulario.aData.idFD_Formulario
        this.Cargar(false)
        this.nuevo = false
      }

      if(this.cargado){
        axiosServices.getConfiguration('buildDinamicos/fin', true, `idFormulario=${this.auxFormulario.idFD_Formulario}&${sParams}` )
        const oBody = this.$store.state.board.columns[1]
        await axiosServices.postConfiguration('buildDinamicos/save', oBody, false, sParams )
        this.getFormularios()
      }

      this.$store.dispatch('cleanCampos')
      this.CancelForm()
    },
    nuevoForm() {
      this.$store.dispatch('cleanCampos')
      this.nuevo = true
      this.formulario = ''
    },
    async Cargar(bCargar = true){
      if(bCargar) this.$emit('CargarCampos')
      const sParams = !this.isSubFormulario ? '' : 'subForm=true'
      this.cargado = true
      // cargo en el registro 1 del board las informacion del formulario que cargue
      this.$store.dispatch('salvarFormulario', this.formulario)

      // obtengo la informacion 
      this.$store.dispatch('cleanCampos')
      const axiosFromularios = await axiosServices.getConfiguration('buildDinamicos/load', true, `idFormulario=${this.formulario}&${sParams}` )
      this.$store.state.board.columns[1].campos = axiosFromularios.aData
      this.$store.state.board.columns[1].idFormulario = this.auxFormulario.idFD_Formulario
      this.formulario = ''

      this.$emit('input', this.auxFormulario == undefined ? '' : this.auxFormulario.NombreFormulario )


    },
    cancelar(){
      this.nuevo=false
      this.cargado = false
       this.$store.dispatch('cleanCampos')
      this.$store.dispatch('salvarFormulario', '')
      this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:'', campos:{}, camposIndex:''})
      this.$emit('input', '')
    },
    async CancelForm(){
      const sParams = !this.isSubFormulario ? '' : 'subForm=true'
      await axiosServices.getConfiguration('buildDinamicos/fin', true, `idFormulario=${this.auxFormulario.idFD_Formulario}&${sParams}` )
     
      this.cargado = false
      this.$store.dispatch('cleanCampos')
      this.$store.dispatch('salvarFormulario', '')
      this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:'', campos:{}, camposIndex:''})
       // timpio el board
      this.$store.dispatch('cleanBoard')
      this.$emit('input', '')
      this.getFormularios()
    }
  },
  watch: {
    newFormName(newValue){
      this.newFormName = newValue.toUpperCase();
    },
    isCancelar(){
      if(this.isCancelar){
        this.CancelForm()
      }
    }
  },
  
}
</script>

<style lang="scss">
.v-text-field.v-text-field--enclosed .v-text-field__details{
  margin-bottom:0px!important;

}
</style>
