
<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <v-checkbox
        v-model="checkbox"
        :label="campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')"
        disabled >
      </v-checkbox>

    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"CheckBox",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
      checkbox : true
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
