<template>
  <v-container class="mt-5" fluid> 
    <v-row v-if="error" no-gutters >
      <v-col cols="12" style="font-size: 12px;" class="red--text mb-3">
        <p>{{errormsg}}</p>
      </v-col>  
    </v-row>
    <v-row no-gutters class="ma-0">
      <v-col cols="3">
        <v-text-field ref="InputID"
          v-model="oValor.id" 
          type="number"
          min="0"
          label="ID"
          placeholder="Valor ID"
          outlined
          dense
        ></v-text-field> 
      </v-col>
      <v-col cols="8">
        <v-text-field
          v-model="oValor.valor"
          label="Valor"
          placeholder="Descripción"
          counter
          :maxlength="maxCaracteres"
          outlined
          dense
        ></v-text-field>       
      </v-col>
      <v-col cols="1">
        <v-btn icon color="green" @click="Insertar()">
          <v-icon small>fas fa-check</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0">
       <v-list-item v-for="(valor, key) in aValor" :key="key" dense class="ma-0 pa-0">
        <v-list-item-content >
          <v-list-item-title  v-text="valor.id"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-content>
          <v-list-item-title v-text="valor.valor"></v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn icon @click="Borrar(valor)">
            <v-icon small color="red lighten-1">fas fa-trash-alt</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-row>
    <v-divider class="mt-5"></v-divider>
  </v-container>    
</template>

<script>
export default {
  name:'ControlInputVal',
  props: {
    noElement: {
      type: Number,
      default: 0
    },
    value : {
      type: Array,
      default: () =>{ return []}
    },
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },
    maxCaracteres:{
      type: Number,
      default: 9999
    }

    
  },
  data() {
    return {
      oValor: {},
      error: false,
      errormsg: '',
      aValor: [],
    }
  },
  mounted () {
    this.cargarDatos()
  },
  methods: {
    Borrar(elemento) {
      this.aValor.splice(this.aValor.findIndex(valor => (valor.id==elemento.id && valor.valor==elemento.valor)) ,1)
    },
    Insertar() {
      if(!this.oValor.id ){
        this.error=true
        this.errormsg = "Se debe tener un ID"
        return
      } 
      if(!this.oValor.valor ){
        this.error=true
        this.errormsg = "Se debe tener un Valor"
        return
      } 
      if(this.aValor.findIndex(valor => (valor.id==this.oValor.id || valor.valor==this.oValor.valor)) >= 0){
        this.error=true
        this.errormsg = "Error de duplicidad de información"
        return
      }
      if(this.noElement!=0 && this.noElement==this.aValor.length){
        this.error=true
        this.errormsg = "Se ha completado el Máximo de registros"
        return
      }


      this.aValor.push(this.oValor)
      this.oValor = {}
      this.error=false
      setTimeout( () => {
        this.$nextTick(() => this.$refs.InputID.focus()); // this works great, just watch out with going to fast !!!
      }, 100);

      this.$emit('input', this.aValor)

    },
    cargarDatos(){
      this.aValor = this.value
      this.error = false
      this.oValor = {}
      if(this.campos.valor.length != 0 && this.campos.origenData){
        this.aValor = this.campos.valor
      }
    }
  },
  watch: {
    camposIndex(newValue, oldValue) {
      if (newValue !== oldValue){
        this.cargarDatos()
      }
    },  
    value(){
      this.cargarDatos()

    }
  },

  
}
</script>

<style lang="scss" scoped>

</style>