// tener presente que se tiene un elemento base y que se trabaja creando columans en este caso son 2 
//  la coluna 0 izquierda es la que tienen tipo de campos y la columan 1 en e centro la mas grande
// es la que muestra como queda el formulario

<template>
  <AppDrop
    @drop="moveCampo"
    class="columnForm"
    :style="{width:column.ancho +'px'}"

  >
    <!-- <AppDrag
      class="columnForm"
      :transferData="{
        type: 'column',
        fromColumnIndex: columnIndex
      }"

    > -->
      <div style=" align-items: center; margin-bottom: 0.5rem;" >
        <v-row no-gutters align="center" justify="center">
          <v-col cols="12" v-if="columnIndex==0">
            <span class="titulo-campos">
            {{ column.name}}
            </span>
          </v-col>
          <!-- Esta seccion son las cabecera del contenedor del centrol donde se hace las cosas -->
          <!-- pregunto si va ha trabajar en formulario o sub  trabajo con una variable preguntado si es SubFormulario-->
          <!-- PRIMER PASO -->
          <v-col cols="12" v-if="columnIndex==1 && !isSeleccion" >
              <div><span class="ntitlesdark--text font-weight-regular">Seleccione lo que quiere trabajar : </span></div>
              <div class="mt-2 card" style="display: flex; justify-content: center;" >
                <v-radio-group 
                v-model="isSubFormulario"
                row>
                <v-radio
                  color="ntitlesdark"
                  :value="false">
                    <template v-slot:label>
                      <div><span class="ntitlesdark--text font-weight-regular">Formulario</span></div>
                    </template>
                </v-radio>
                <v-radio
                  color="ntitlesdark"
                  :value="true">
                    <template v-slot:label>
                      <div><span class="ntitlesdark--text font-weight-regular">Sub Formulario</span></div>
                    </template>
                </v-radio>
                </v-radio-group>
              </div>
              <div style="display: flex; justify-content: center;" >
                <v-btn
                  class="ma-2 btn-rounded"
                  color="btndest" 
                  @click="Seleccionar"
                  large depressed dark
                  >
                  Seleccionar
                </v-btn>
              </div>
          </v-col>
          <!-- SEGUNDO PASO  -->
          <v-col cols="12" v-if="columnIndex==1 && isSeleccion">
           <v-row no-gutters align="center" justify="left">
              <v-col cols="12">
                <v-btn class="mr-2" fab dark x-small depressed color="btndest" @click="Regresar()">
                  <v-icon dark>
                    fas fa-chevron-left
                  </v-icon>
                </v-btn>
                <span class="ntitlesdark--text text-min">{{ isSubFormulario ? 'Sub Formulario : ' : 'Formulario : '}}  {{ FormName }} </span>
              </v-col>
           </v-row>  
          </v-col>
        </v-row>  
      </div>
      <div class="pa-0" style="min-height:100px;">
        <ColumnTask
          v-for="(campos, _camposIndex) of column.campos"
          :key="_camposIndex"
          :campos="campos"
          :camposIndex="_camposIndex"
          :column="column"
          :columnIndex="columnIndex"
          :board="board" />

        <!-- para dejar el boton de crear nuevas tareas  -->
        <!-- <input
          type="text"
          class="nuevo"
          placeholder="+ Enter new task"
          @keyup.enter="createTask($event, column.tasks)"
        />  --> 
      </div>
      <div v-if="columnIndex==1 && isSeleccion">
         <FormControl
          class="mt-3"
          v-model="FormName"
          :isSubFormulario="isSubFormulario"
          :isCancelar="isCancelar"
          @CargarCampos="CargarCampos"
        />
      </div>
      
    <!-- </AppDrag> -->

  </AppDrop>
</template>

<script>
// import AppDrag from '@/components/dragdrop/AppDrag'
import ColumnTask from '@/components/dragdrop/ColumnTask.vue'
import AppDrop from '@/components/dragdrop/AppDrop'
import movingTasksAndColumnsMixin from '@/mixins/movingTasksAndColumnsMixin'
import FormControl from '@/components/dragdrop/FormControl.vue'


export default {
  name:"BoardColumn",
  components: {
    ColumnTask,
    AppDrop,
    FormControl,
    // AppDrag,
  },
  mixins: [movingTasksAndColumnsMixin],
  data() {
    return {
      FormName: 'Sin Nombre de Formulario',
      isSubFormulario: false,     // Se indica que es formulario al iniciar 
      isSeleccion : false,        // Me indica si selecciono entre Formulario y Subformulario.  
      isCancelar : false
    }
  },
  methods: {
    pickupColumn (e, fromColumnIndex) {
      e.dataTransfer.effectAllowed = 'move'
      e.dataTransfer.dropEffect = 'move'

      e.dataTransfer.setData('from-column-index', fromColumnIndex)
      e.dataTransfer.setData('type', 'column')
    },
    Regresar () {
      if(this.FormName == ''){
        this.isCancelar = false
        this.isSeleccion = false
        this.FormName = ''
        this.$store.dispatch('cleanCampos')
        this.$store.dispatch('salvarFormulario', '')
        this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:'', campos:{}, camposIndex:''})
        // timpio el board
        this.$store.dispatch('cleanBoard')
      } else{
        this.isCancelar = true
      }
    },
    Seleccionar () {
      this.isSeleccion = true
      this.FormName = ''
      this.$emit('Selecciono', this.isSubFormulario)
      this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:'', campos:{}, camposIndex:''})
    },
    CargarCampos() {
      this.$emit('Selecciono', this.isSubFormulario)
      this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:'', campos:{}, camposIndex:''})
    },
    watch:{
      FormName(){
        if(this.FormName==''){
          this.isCancelar = false
          this.isSeleccion = false
          this.FormName = ''
        }
        
      }
    }

    // createTask (e, tasks) {
    //   this.$store.commit('CREATE_TASK', {
    //     tasks,
    //     name: e.target.value
    //   })
    //   e.target.value = ''
    // }
  }
}
</script>

<style lang="css">
.columnForm {
  padding: 0.5rem;
  margin-right: 1rem;
  /* min-width: 500px; */
  text-align: left;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border-radius: .25rem;
  background: #E5F3F9;
  color:#064b83;
  font-size: 1.3em;
  height: 93vh;
  overflow: auto;
} 
.titulo-campos{
  color: #000000;
  font-size: 0.85em;
}
</style>
