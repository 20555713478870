<template>
  <div>
    <v-combobox
      v-model="grupo"
      :items="aGrupos"
      item-text="NombreGrupo"
      item-value="idFD_Grupo"
      dense
      filled
      label="Grupos"
      @change="$emit('input', grupo.idFD_Grupo)"
      :search-input.sync="search"
    >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            Este Valor es nuevo  "<strong>{{ search }}</strong>". Quiere crearlo?
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon x-small @click="Crear">
            <v-icon color="green lighten-1">fas fa-check</v-icon>
          </v-btn>
          <v-btn icon x-small @click="Cancel">
            <v-icon color="red lighten-1">fas fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    
    </v-combobox>
  </div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'

export default {
  name:'GrupoControl',
  props: {
    value: {
      // type:String,
      // default: ''
    },
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    }, 
  },
  data() {
    return {
      grupo: null,
      aGrupos: [],
      search: null
    }
  },
  mounted () {
    this.CargarGrupos()
    this.cargar()
    
  },
  updated () {
    this.cargar()
  },
  methods: {
    async CargarGrupos(){
        const axiosGrupos = await axiosServices.getConfiguration('buildDinamicos/grupos', true )
        this.aGrupos = axiosGrupos.aData
    },
    cargar(){
      // pregunta es un string con el numero de idFD_Grupo, pero aqui se trabaja como un objeto por el v-combobox
      this.grupo = this.value !=0 ? this.aGrupos.filter(grupo => grupo.idFD_Grupo == this.value)[0] : ''
    },
    async Crear(){
      const oBody = { 
        'Grupo': this.search
      }

      const axiosPostGrupos =  await axiosServices.postConfiguration('buildDinamicos/grupos', oBody)

      this.CargarGrupos()
      this.grupo = axiosPostGrupos.aData

      this.$emit('input',this.grupo.idFD_Grupo)
      

    },
    Cancel(){
      this.grupo = {'idFD_Grupo':"", 'NombreGrupo':""}
      this.search = ""
    },


  },
  watch: {
    camposIndex(){
      this.cargar()
    }
  },
  
}
</script>

<style lang="scss" scoped>

</style>