
<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      
      <v-text-field
        disabled
        prepend-icon="fas fa-at"
        :label="campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')"
      >
      </v-text-field>

    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"CopiaCorreo",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
      checkbox : true
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
