<template>
  <div> 
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <!-- <v-container fluid> -->
        <!-- <row justify="center" align="center" subheading> -->
        <span>Pregunta: {{campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')}}</span>
        <div subheading class="my-2"> 
          <v-img
            src="@/assets/firmeAqui.png"
            max-height="80"
            max-width="600"
            contain
            clas="ma-10"
          ></v-img>
        </div>   
        <!-- </row> -->
      <!-- </v-container> -->
    </ControlOption>


  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"Firma",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
