<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <div class="mb-n2">
        <span>Pregunta: {{campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')}}</span>
      </div>
        <v-row
          class="mx-1"
          align="start"
          justify="start" >
          <v-radio-group v-model="aValores" disabled dense >
            <v-radio class="ma-0 pa-0 text-sm-right" 
              v-for="(valor, key) in aValores" :key="key"
              :label="valor.valor"
            ></v-radio>
          </v-radio-group>
      </v-row>

    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"RadioButton",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
      aValores: [{id:0, valor:`Sin Valor 0`}]
    }
  },
  mounted () {
    if(this.aValores.length == 1 && this.aValores[0].id === 0 && this.aValores[0].valor === `Sin Valor 0`){
      this.aValores = []
      for(let i=0; i<5; i++){
        this.aValores.push({id:i, valor:`Sin Valor ${i+1}` })
      }
    }
    if(this.campos.valor.length != 0){
      this.aValores = this.campos.valor
    }
  },
  watch: {
    campos(newCampos) {
      if(newCampos.valor.length != 0){
        this.aValores = newCampos.valor
      }
      
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
