<template>
    <div id= "Board" class="board pa-1">
      <div id= "TableroInterno" class="d-flex d-flex-row boardColum cont-shadown-rounded" style="align-items: stretch;">
        <!-- se monta un tablero compleo para drag drop de dos Columnas -->
        <BoardColumn
          v-for="(column, _columnIndex) of board.columns"
          :key="_columnIndex"
          :column="column"
          :columnIndex="_columnIndex"
          :board="auxBoard" 
          @Selecciono="CargarCampos"/>

        <!-- Se monta otra columan al lado del boadColumn  -->
        <div id="Configuracion" class="sidebar cont-shadown-rounded" style="align-items: stretch;">
          <div>
            <v-container fluid>
              <v-row no-gutters>
                <v-col cols="11">
                  <h3><span class="font-weight-regular titlesdark--text">Configuración</span></h3>
                </v-col>
                <v-col cols="1" align-end>
                  <v-icon medium class="font-weight-black titlesdark--text">fas fa-cogs</v-icon>
                </v-col>  
              </v-row>  
              <v-divider class="mt-5"></v-divider>
            </v-container>          
          </div>
        
          <component :is="componentSelect.nombreTipo"
          :campos="componentSelect.campos" 
          :camposIndex="componentSelect.camposIndex"
          >
          </component> 
        </div>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import axiosServices from '@/services/axiosServices.js'
import BoardColumn from '@/components/dragdrop/BoardColumn.vue'
import defaultBoard from '@/components/dragdrop/default-board.js'
import EditTextTextoConfig from "@/components/dragdrop/Campos/EditTextTextoConfig.vue"
import EditTextNumerosConfig from "@/components/dragdrop/Campos/EditTextNumerosConfig.vue"
import EditTextAlfanumericoConfig from "@/components/dragdrop/Campos/EditTextAlfanumericoConfig.vue"
import FirmaConfig from "@/components/dragdrop/Campos/FirmaConfig.vue"
import CalendariosConfig from "@/components/dragdrop/Campos/CalendariosConfig.vue"
import GrupoConfig from "@/components/dragdrop/Campos/GrupoConfig.vue"
import FotoConfig from "@/components/dragdrop/Campos/FotoConfig.vue"
import ToggleButtonConfig from "@/components/dragdrop/Campos/ToggleButtonConfig.vue"
import ItemConfig from "@/components/dragdrop/Campos/ItemConfig.vue"
import RadioButtonConfig from "@/components/dragdrop/Campos/RadioButtonConfig.vue"
import CheckBoxConfig from "@/components/dragdrop/Campos/CheckBoxConfig.vue"
import SelectConfig from "@/components/dragdrop/Campos/SelectConfig.vue"
import MultiSelectConfig from "@/components/dragdrop/Campos/MultiSelectConfig.vue"
import ImagenConfig from "@/components/dragdrop/Campos/ImagenConfig.vue"
import InformacionConfig from "@/components/dragdrop/Campos/InformacionConfig.vue"
import CorreoConfig from "@/components/dragdrop/Campos/CorreoConfig.vue"


export default {
  name:'buildDinamicos',
  components: {
    BoardColumn,
    EditTextTextoConfig,
    EditTextNumerosConfig,
    EditTextAlfanumericoConfig,
    FirmaConfig,
    CalendariosConfig,
    GrupoConfig,
    FotoConfig,
    ToggleButtonConfig,
    ItemConfig,
    RadioButtonConfig,
    CheckBoxConfig,
    SelectConfig,
    MultiSelectConfig,
    ImagenConfig,
    InformacionConfig,
    CorreoConfig,
    
  },
  data() {
    return {
      auxBoard: {},
      campos: {},
    }
  }, 
  created () {
    // this.getCampos()
    this.$store.dispatch('cambiarComponentSelet' , {nombreTipo:'', campos:{}, camposIndex:''})
    this.$store.dispatch('salvarBoard' , defaultBoard)

  },
  methods: {
    async getCampos(isSubFormulario) {
      const sParams = !isSubFormulario ? '' : 'subForm=true'
      const aCampos = await axiosServices.getConfiguration('buildDinamicos/campos', true, sParams)

      // con la respuetas recorrod el registroa aCampos y lo mento en el formato adecaudo a auxBoard
      this.auxBoard = defaultBoard

      this.auxBoard.columns[0].campos = []

      aCampos.aData.forEach( ( element, key) => {
        this.auxBoard.columns[0].campos[key] = {
          id: key,
          ancho: 90,
          userAssigned: null,
          idTipoCampo: element.idFD_Tipo_Campo,
          NombreTipo: element.NombreTipo,
          name: element.NombreVisualizar,
          idPregunta: null,
          pregunta:"Sin Pregunta",
          obligatorio: "",
          valor: [] ,
        }
      })

      // registro el campo para los grupos 
      if (!isSubFormulario){
        this.auxBoard.columns[0].campos.push({
            id: this.auxBoard.columns[0].campos.length,
            ancho: 100,
            userAssigned: null,
            idTipoCampo: 0,
            NombreTipo: 'Grupo',
            name: 'Grupo',
            idPregunta: null,
            pregunta:"Sin Pregunta",
            valor: [] ,
          })
      }


      this.$store.dispatch('salvarBoard' , this.auxBoard)
      
    },
    CargarCampos(isSubFormulario) {
      this.getCampos(isSubFormulario)
    },
  },
  computed: {

    ...mapState(['board', 'componentSelect'])
  },

    
}
</script>

<style lang="scss" scoped>

  .board {
    background: white;
    width:100%;
    height: 100vh; 
    overflow: auto;
    padding: 0.3rem;
    padding-left: 1.5rem;
    // margin-left: 3.1rem;
  }
  .boardColum {
    overflow: auto;
    padding: 1rem;
  }
  .sidebar {
    background: #E5F3F9;
    width:30%;
    height: 93vh; 
    overflow: auto;
    padding: 10px 0px;

  }

</style>