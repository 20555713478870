<template>
   <!-- estas son las columnas internas las que muestas las task para este caso son igual a campos-->
   <div>
  <AppDrop
    @drop="moveCampo">

    <AppDrag
      :class="{'task':true, 'accent':campos.ancho==100}"
      :style="{'width':campos.ancho + '%'}"
      :transferData="{
        type: 'task',
        fromColumnIndex: columnIndex,
        fromTaskIndex: camposIndex
      }" >

      <!-- En esta seccion dibujo el campo como quiero que se vea -->

      <v-container fluid ml-n3 >
        <v-row no-gutters>
          <v-col cols="10">
            <h3 v-if="columnIndex == 0" ><span  class="font-weight-medium titlesdark--text">{{ campos.name }} </span></h3>
            <h5 v-else ><span class="font-weight-light titlesdark--text">{{ campos.name }} </span></h5>
          </v-col>
          <v-col cols="2" v-if="camposIndex==getscomponentSelect.camposIndex && columnIndex !== 0">
            <span class="font-weight-light titlesdark--text" >En edición </span>
            <v-avatar
              color="green"
              size="12"
            >
            </v-avatar>
          </v-col>  
        </v-row>  
      </v-container>    
      <component :is="campos.NombreTipo"
        v-if="columnIndex!=0"
        :campos="campos" 
        :camposIndex="camposIndex">
      </component>
    </AppDrag>
  </AppDrop>
  </div>
</template>

<script>
import {mapGetters} from 'vuex' 
import movingTasksAndColumnsMixin from '@/mixins/movingTasksAndColumnsMixin'
import AppDrag from '@/components/dragdrop/AppDrag'
import AppDrop from '@/components/dragdrop/AppDrop'
import EditTextTexto from "@/components/dragdrop/Campos/EditTextTexto.vue"
import EditTextNumeros from "@/components/dragdrop/Campos/EditTextNumeros.vue"
import EditTextAlfanumerico from "@/components/dragdrop/Campos/EditTextAlfanumerico.vue"
import Firma from "@/components/dragdrop/Campos/Firma.vue"
import Calendarios from "@/components/dragdrop/Campos/Calendarios.vue"
import Grupo from "@/components/dragdrop/Campos/Grupo.vue"
import Foto from "@/components/dragdrop/Campos/Foto.vue"
import ToggleButton from "@/components/dragdrop/Campos/ToggleButton.vue"
import Item from "@/components/dragdrop/Campos/Item.vue"
import RadioButton from "@/components/dragdrop/Campos/RadioButton.vue"
import CheckBox from "@/components/dragdrop/Campos/CheckBox.vue"
import Select from "@/components/dragdrop/Campos/Select.vue"
import MultiSelect from "@/components/dragdrop/Campos/MultiSelect.vue"
import Imagen from "@/components/dragdrop/Campos/Imagen.vue"
import Informacion from "@/components/dragdrop/Campos/Informacion.vue"
import Correo from "@/components/dragdrop/Campos/Correo.vue"
// import axiosServices from '@/services/axiosServices.js'

export default {
  name:"ColumnTask",
  components: { 
    AppDrag,
    AppDrop,
    EditTextTexto,
    EditTextNumeros,
    EditTextAlfanumerico,
    Firma,
    Calendarios,
    Grupo,
    Foto,
    ToggleButton,
    Item,
    RadioButton,
    CheckBox,
    Select,
    MultiSelect,
    Imagen,
    Informacion,
    Correo,
  },
  mixins: [movingTasksAndColumnsMixin],
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },
    ancho:{
      type: String,

    }
  },
  data() {
    return {
      TablasItems : [],
      TablaSelect : '',
      ColumItems  : [],
      ColumSelect : '',
      TablaSelectold : '',
      ColumSelectold : '',
      vinculado   : false,
    }
  },
  mounted () {
  },
  methods: {
  },
  computed: {

     ...mapGetters(['getscomponentSelect'])
  },
  watch: {

  },
}
</script>

<style lang="css">
.task {
  font-size: 12px;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  background-color: #f7fafc;
  margin-bottom: 0.4rem;
  padding: 0.5rem;
}
</style>
