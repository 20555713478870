<template>
  <div>
    <v-container fluid >
      <v-row no-gutters>
        <v-col cols="12" class="rounded-5 white pa-2 my-2">
          <h3 class="mb-2 text-medium text-primary font-normal">Descripción:</h3>
          <p class="p-nomargin">Este campo permite subir una imagen que se mostrará en la Aplicación. <br><br>El tamaño no puede ser superior a 1 MB</p>
        </v-col> 
        <v-col cols="12" class="white rounded-5 pa-2 my-2">
          <PreguntaControl
            v-model="pregunta"
            :campos="campos"
            :camposIndex="camposIndex" 
          >  
          </PreguntaControl>
        </v-col>
        <v-col cols="12" align-end class="ClassFile white rounded-5 pa-2 my-2" >
          <p>
            Nombre del Archivo cargado actualmente:
          </p> 
          <p class="text-center grey lighten-2 pa-2 "> 
            <b class="text-center" style="font-size: 12px;"> {{computedsFileName}} </b>
          </p>
          <v-btn
            elevation="2"
            color="success"
            x-small
            @click="CambiarArchivo = true"
            class="mb-2"
          >
            <h3>Cambiar</h3>
          </v-btn>
          <UploadFile 
            v-if="CambiarArchivo"
            v-model="sFileName"
            accept="image/png, image/jpeg, image/bmp"
            :path="aRutasImagenes.rutaSalvar"
          >
          </UploadFile>
        </v-col>
      </v-row>  
      <v-row no-gutters>
        <v-col cols="12" align-end v-if="errors.length" style="font-size: 12px;" class="red--text">
          <p>
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul>
              <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
            </ul>
          </p>
        </v-col>  
        <v-col cols="12" align-end class="mt-5" >
          <v-btn block color="btncolor" elevation="2" dark @click="Guardar">
           <span> Guardar </span>
          </v-btn>
        </v-col> 
      </v-row>  
    </v-container>   
  </div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import PreguntaControl from '@/components/dragdrop/Campos/PreguntaControl.vue'
import UploadFile from '@/components/UploadFile.vue'

export default {
  name:"ImagenConfig",
  components: {
    PreguntaControl,
    UploadFile,
  },
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    }, 
  },
  data() {
    return {
      pregunta: '',
      obligatorio: 0,
      aPreguntas: [],
      errors: [],
      sFileName: '',
      CambiarArchivo: false,
      aRutasImagenes: [],
    }
  },
  mounted () {
    this.getPreguntas()
    this.cargarDatos()
    this.getRutasImagenes()
  },
  methods: {
    async getPreguntas() {
      const axiosPreguntas = await axiosServices.getConfiguration('buildDinamicos/preguntas', true )
      this.aPreguntas = axiosPreguntas.aData
    },
    validarInfo(){

      this.errors = []

      if(this.pregunta == '' || this.pregunta == null || this.pregunta == 'undefined' ){
       this.errors.push('Debe Seleccionar una pregunta!')
      }
      if(this.sFileName.length == 0 || this.sFileName === '_Sin Imagen'){
       this.errors.push('Debe indicar un archivo a cargar!')
      }
    
      // valido si la pregunta no esta repetida
      const auxboard = this.$store.state.board.columns[1].campos
     
      const preguntaIndex = auxboard.findIndex(campo =>
        ( campo.idPregunta == this.pregunta && campo.NombreTipo != "Grupo" && campo.NombreTipo !="Item")
      )

      if(preguntaIndex != this.camposIndex && preguntaIndex > -1){
       this.errors.push('La pregunta seleccionada esta en otro campo, No la puede emplear!')
      }

      if( this.errors.length==0){
        return false 
      } else {
        return true 
      }

    },
    Guardar(){

      if( this.validarInfo() ){
        return
      }

      const auxPregunta = this.aPreguntas.filter( preguntas => preguntas.idFD_Pregunta == this.pregunta )

      const auxcampo = {
          id: this.campos.id,
          ancho: this.campos.ancho,
          userAssigned: this.campos.userAssigned,
          idTipoCampo: this.campos.idTipoCampo,
          NombreTipo: this.campos.NombreTipo,
          name: this.campos.name,
          idPregunta: this.pregunta ,
          pregunta: auxPregunta[0].Pregunta,
          obligatorio: this.obligatorio,
          valor: [{
              id:"1", 
              valor: this.sFileName.indexOf(this.aRutasImagenes.rutaMostrar) < 0 ?  
                              `${this.aRutasImagenes.rutaMostrar}${this.sFileName}` :
                              `${this.sFileName}`

              }],
      }

      this.$store.dispatch('salvarCampoBoard',{camposIndex:this.camposIndex, campo:auxcampo })
      this.$store.dispatch('notificaciones/SetMensaje', "Información almacenada correctamente");
      this.$store.dispatch('notificaciones/SetColor','success');
      this.$store.dispatch('notificaciones/ShowNotificacion', true);
    },
    cargarDatos() {
      this.errors = []
      this.pregunta = this.campos.idPregunta == 0 ? 0 : this.campos.idPregunta
      this.sFileName = this.campos.valor.length == 0 ? '_Sin Imagen' : this.campos.valor[0].valor
    },
    async getRutasImagenes() {
      const axiosRutasImagenes = await axiosServices.getConfiguration('rutasimg', true )
      this.aRutasImagenes = axiosRutasImagenes.aData
    }
  },
  computed: {
    computedsFileName() {
      return this.sFileName.substr(this.sFileName.indexOf('__') + 2)  // Sumo 2 por que es doble simbolo _
    } 
  },
  watch: {
    camposIndex(newValue, oldValue) {
      if (newValue !== oldValue){
        this.cargarDatos()
      }
    },
    pregunta(){
      this.getPreguntas()
    },
    computedsFileName(NewValue, oldValue){
      if(NewValue !== oldValue){
        this.CambiarArchivo = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.ClassFile { 
  border: solid 1px;
  padding: 10px;
}

</style>