<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <v-text-field
        :label="campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')"
        placeholder="  "
        disabled
      ></v-text-field>

    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"EditTextAlfanumerico",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
