export default {
  props: {
    column: {
      type: Object,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    },
    board: {
      type: Object,
      required: true
    }
  },
  methods: {
    moveCampo ({ fromColumnIndex, fromTaskIndex }) {
      // const fromTasks = this.board.columns[fromColumnIndex].tasks

      // si no hay un formulario seleccionado no se permite mover campos

      if(!this.$store.state.board.columns[1].idFormulario && !this.$store.state.board.columns[1].idFormulario != '') {
        this.$store.dispatch('notificaciones/SetMensaje','Por favor crear o seleccionar un Formulario o SubFormulario para trabajar !' );
        this.$store.dispatch('notificaciones/SetColor','error')
        this.$store.dispatch('notificaciones/ShowNotificacion',true);
        return
      }

      // Se infoca el metodo del stora para guardar el campo en la columna index 1
      this.$store.commit('MOVE_CAMPO', {
        fromTasks :this.board.columns[fromColumnIndex].campos,
        fromTaskIndex,
        toTasks: this.column.campos,
        toTaskIndex: this.camposIndex,
        fromColumn: this.board.columns[fromColumnIndex].name,
        toColumn : this.column.name
      })
 
    },
  }
}
