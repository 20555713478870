<template>
  <div>
    <v-container fluid >
      <v-row no-gutters>
        <v-col cols="12" class="rounded-5 white pa-2 my-2">
          <h3 class="mb-2 text-medium text-primary font-normal">Descripción:</h3>
          <p class="p-nomargin">
              Este componente permite seleccionar multiples elementos, en una lista. <br>
              Se requiere minimo 2 elementos 
          </p>
        </v-col>
        <v-col cols="12" class="white rounded-5 pa-2 my-2">
          <PreguntaControl
            v-model="pregunta"
            :campos="campos"
            :camposIndex="camposIndex" 
          >  
          </PreguntaControl>
        </v-col>
        <v-col cols="12" align-end class="white rounded-5 pa-2 my-2">
          <h3 class="mb-2 text-medium text-primary font-normal">Obligatorio</h3>
          <v-radio-group v-model="obligatorio" row >
            <v-radio
              label="SI"
              value="1"
            ></v-radio>
            <v-radio
              label="NO"
              value="0"
            ></v-radio>
          </v-radio-group>
        </v-col>  
      </v-row>  
      <v-row no-gutters >
        <v-col cols="12" class="white rounded-5 pa-2 my-2">
          <span>Origen de los datos</span>
          <v-radio-group v-model="origenData" row >
            <v-radio
              label="Manual"
              :value="true"
            ></v-radio>
            <v-radio
              label="Tabla"
              :value="false"
            ></v-radio>
          </v-radio-group>
        </v-col>  
        <v-col v-if="origenData" cols="12" class="white rounded-5 pa-2 my-2">
          <span>Valores</span>
          <ControlInputVal
            v-model="aValor"
            :noElement="50"
            :campos="campos" 
            :camposIndex="camposIndex">
          ></ControlInputVal>
        </v-col> 
        <v-col v-else cols="12" class="white rounded-5 pa-2 my-2">
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="tablaOrigen"
                :items="aTablaOrigen"
                item-text="LabelTabla"
                item-value="idFD_Tabla_Para_Select"
                dense
                filled
                label="Tala Origen"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" class="mt-n9" style="font-size: 12px;" v-if="aTableValor.length > 0">
              <span>Información de muestra (5 primeros registros)</span>
              <div align="center" justify="space-around">
              <v-progress-circular v-if="CargandoTable"
                :size="20"
                color="primary"
                indeterminate
              ></v-progress-circular>
              </div>
              <v-list-item v-for="(Tablevalor, key) in aTableValor.slice(0,5)" :key="key" dense >
                <v-list-item-content>
                  <v-list-item-title  v-text="Tablevalor.id"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title v-text="Tablevalor.valor"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-col> 
      </v-row>  
      <v-row no-gutters>
        <v-col cols="12" align-end v-if="errors.length" style="font-size: 12px;" class="red--text">
          <p >
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul>
              <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
            </ul>
          </p>
        </v-col>  
        <v-col cols="12" align-end>
          <v-btn block color="btncolor" elevation="2" dark @click="Guardar">
           <span> Guardar </span>
          </v-btn>
        </v-col> 
      </v-row>  
    </v-container>   
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axiosServices from '@/services/axiosServices.js'
import ControlInputVal from '@/components/dragdrop/Campos/ControlInputVal.vue'
import PreguntaControl from '@/components/dragdrop/Campos/PreguntaControl.vue'

export default {
  name:"MultiSelectConfig",
  components: {
    ControlInputVal,
    PreguntaControl,
  },
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    }, 
  },
  data() {
    return {
      pregunta  : '',
      obligatorio : '',
      aPreguntas : [],
      errors : [],
      aValor : [],
      aTableValor : [],
      origenData : '',     // true es manual
      tablaOrigen : '',
      aTablaOrigen : [],
      CargandoTable : false,
    }
  },
  mounted () {
    this.getCaposSelect()
    this.cargarDatos()
  },
  methods: {
    async getCaposSelect() {
      const axiosPreguntas = await axiosServices.getConfiguration('buildDinamicos/preguntas', true )
      this.aPreguntas = axiosPreguntas.aData

      this.getTablas()

    },
    validarInfo(){

      this.errors = []

      if(this.pregunta == '' || this.pregunta == null || this.pregunta == 'undefined' ){
       this.errors.push('Debe Seleccionar una pregunta!')
      }
      if(this.obligatorio == ''){
       this.errors.push('Debe indicar si es obligatorio!')
      }

      // valido si la pregunta no esta repetida
      const auxboard = this.$store.state.board.columns[1].campos
     
      const preguntaIndex = auxboard.findIndex(campo =>
        ( campo.idPregunta == this.pregunta && campo.NombreTipo != "Grupo" && campo.NombreTipo !="Item")
      )

      if(preguntaIndex != this.camposIndex && preguntaIndex > -1){
       this.errors.push('La pregunta seleccionada esta en otro campo, No la puede emplear!')
      }

      if(this.origenData && this.aValor.length == 0){
       this.errors.push('Debe contar con dos registro de Valores mínimo!')
      }
      if(this.origenData && this.aValor.length < 2){
       this.errors.push('El número de registros de Valores deber ser 2 Minimo!')
      }

      if(!this.origenData && this.aTableValor.length == 0){
       this.errors.push('La tabla seleccionada no cuenta con información!. Hable con el Administardor')
      }
      if(!this.origenData && this.aTableValor.length < 2){
       this.errors.push('El número de registros de Valores deber ser 2 Minimo!. Hable con el Administardor')
      }


      if( this.errors.length==0 ){
        return false 
      } else {
        return true 
      }

    },
    Guardar(){

      if( this.validarInfo() ){
        return
      }

      const auxPregunta = this.aPreguntas.filter( preguntas => preguntas.idFD_Pregunta == this.pregunta )

      const auxcampo = {
          id : this.campos.id,
          ancho : this.campos.ancho,
          userAssigned : this.campos.userAssigned,
          idTipoCampo : this.campos.idTipoCampo,
          NombreTipo : this.campos.NombreTipo,
          name : this.campos.name,
          idPregunta : this.pregunta ,
          pregunta : auxPregunta[0].Pregunta,
          obligatorio : this.obligatorio,
          origenData : this.origenData ,
          tablaOrigen : this.tablaOrigen, 
          valor : (this.origenData ? this.aValor : this.aTableValor)

      }

      this.$store.dispatch('salvarCampoBoard',{camposIndex:this.camposIndex, campo:auxcampo })
      this.$store.dispatch('notificaciones/SetMensaje', "Información almacenada correctamente");
      this.$store.dispatch('notificaciones/SetColor','success');
      this.$store.dispatch('notificaciones/ShowNotificacion', true);
    },
    cargarDatos() {
      this.errors = []
      this.aValor = []
      this.aTableValor = [] 


      this.pregunta = this.campos.idPregunta == 0 ? 0 : this.campos.idPregunta
      this.obligatorio = this.campos.obligatorio
      this.origenData  = this.campos.origenData

      this.aValor = this.campos.origenData ?  this.campos.valor : []
     
      this.aTableValor = !this.campos.origenData ? this.campos.valor : []
      this.tablaOrigen = !this.campos.origenData ? this.campos.tablaOrigen : '' 




    },
    async getTablas() {
      this.CargandoTable = true
      const axiosTablas = await axiosServices.getConfiguration('buildDinamicos/select_tablas', true )
      this.aTablaOrigen = axiosTablas.aData
      this.CargandoTable = false
    }
  },
  computed: {

    ...mapGetters(['getsboard'])

  },
  watch: {
    camposIndex(newValue, oldValue) {
      if (newValue !== oldValue){
        this.getCaposSelect()
        this.cargarDatos()
      }
    },
    async tablaOrigen(newValue){
      
      if(newValue != undefined ){
        await this.getTablas()
      }

      const oTable = this.aTablaOrigen.filter( TableSelect => TableSelect.idFD_Tabla_Para_Select == newValue )[0]
      if(oTable != undefined){
          const sParams = `TableName=${oTable.NombreTabla}&Primarykey=${oTable.Primaria}&Valor=${oTable.Loockup}`

          this.CargandoTable = true
          const axiosValueTable = await axiosServices.getConfiguration('buildDinamicos/select_tablas_info', true, sParams  )
          this.aTableValor = Object.keys(axiosValueTable.aData ).length > 0 ?  axiosValueTable.aData : []
          this.CargandoTable = false
       }


    },
    origenData(newValue){
      if( newValue ){
        this.aValor = (newValue == this.campos.origenData) ? this.campos.aValor : []
        this.tablaOrigen= ''  // lo borro para volvelo a cargar 

      } else {
        this.aTableValor = []
        this.tablaOrigen = (newValue == this.campos.origenData) ? this.campos.tablaOrigen : ''
      }
    },
    pregunta(){
      this.getCaposSelect()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>