<template>
  <div>
    <v-combobox
      ref="cbPregunta"
      v-model="pregunta"
      :items="aPreguntas"
      item-text="Pregunta"
      item-value="idFD_Pregunta"
      dense
      filled
      label="Pregunta"
      @change="Emitir"
      :search-input.sync="search"
    >
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            Este Valor es nuevo  "<strong>{{ search }}</strong>". Quiere crearlo?
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon x-small @click="Crear">
            <v-icon color="green lighten-1">fas fa-check</v-icon>
          </v-btn>
          <v-btn icon x-small @click="Cancel">
            <v-icon color="red lighten-1">fas fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    
    </v-combobox>
  </div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'

export default {
  name:'PreguntaControl',
  props: {
    value: {
      // type:String,
      // default: ''
    },
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    }, 
  },
  data() {
    return {
      pregunta: null,
      aPreguntas: [],
      search: null
    }
  },
  mounted () {
    this.CargarPreguntas()
    this.cargar()
    
  },
  updated () {
    this.cargar()
  },
  methods: {
    async CargarPreguntas(){
        const axiosPreguntas = await axiosServices.getConfiguration('buildDinamicos/preguntas', true )
        this.aPreguntas = axiosPreguntas.aData
    },
    cargar(){
      // pregunta es un string con el numero de idFD_pregunta, pero aqui se trabaja como un objeto por el v-combobox
      this.pregunta = this.value !=0 ? this.aPreguntas.filter(pregunta => pregunta.idFD_Pregunta == this.value)[0] : ''
    },
    async Crear(){
      const oBody = { 
        'Pregunta': this.search
      }

      const axiosPostPregunta =  await axiosServices.postConfiguration('buildDinamicos/preguntas', oBody)
      await this.CargarPreguntas()
      this.pregunta = await axiosPostPregunta.aData
      await this.$emit('input',this.pregunta.idFD_Pregunta)
      this.$refs.cbPregunta.blur()
    },
    Cancel(){
      this.pregunta = {'idFD_Pregunta':"", 'Pregunta':""}
      this.search = ""
    },
    Emitir(){
      if(this.pregunta !== null){
        this.$emit('input', this.pregunta.idFD_Pregunta)
      }
    }

  },
  watch: {
    camposIndex(){
      this.cargar()
    }
  },
  
}
</script>

<style lang="scss" scoped>

</style>