<template>
  <div>
    <v-container fluid >
      <v-row no-gutters>
        <v-col cols="12" class="rounded-5 white pa-2 my-2">
          <h3 class="mb-2 text-medium text-primary font-normal">Descripción:</h3>
          <p class="p-nomargin">
             Este campo permite que el usuario digite solo Números
          </p>
        </v-col> 
        <v-col cols="12" class="white rounded-5 pa-2 my-2">
          <PreguntaControl
            v-model="pregunta"
            :campos="campos"
            :camposIndex="camposIndex" 
          >  
          </PreguntaControl>
        </v-col>
        <v-col cols="12" align-end class="white rounded-5 pa-2 my-2">
          <h3 class="mb-2 text-medium text-primary font-normal">Obligatorio</h3>
          <v-radio-group v-model="obligatorio" row >
            <v-radio
              label="SI"
              value="1"
            ></v-radio>
            <v-radio
              label="NO"
              value="0"
            ></v-radio>
          </v-radio-group>
        </v-col>  
      </v-row>  
      <v-row no-gutters>
        <v-col cols="12" align-end v-if="errors.length" style="font-size: 12px;" class="red--text">
          <p >
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul>
              <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
            </ul>
          </p>
        </v-col>  
        <v-col cols="12" align-end>
          <v-btn block color="btncolor" elevation="2" dark @click="Guardar">
           <span> Guardar </span>
          </v-btn>
        </v-col> 
      </v-row>  
    </v-container>   
  </div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import PreguntaControl from '@/components/dragdrop/Campos/PreguntaControl.vue'

export default {
  name:"EditTextNumerosConfig",
  components: {
    PreguntaControl,
  },
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    }, 
  },
  data() {
    return {
      pregunta  : '',
      obligatorio : '',
      aPreguntas : [],
      errors: [],
    }
  },
  mounted () {
    this.getPreguntas()
    this.cargarDatos()
  },
  methods: {
    async getPreguntas() {
      const axiosPreguntas = await axiosServices.getConfiguration('buildDinamicos/preguntas', true )
      this.aPreguntas = axiosPreguntas.aData
    },
    validarInfo(){

      this.errors = []

      if(this.pregunta == '' || this.pregunta == null || this.pregunta == 'undefined' ){
       this.errors.push('Debe Seleccionar una pregunta!')
      }
      if(this.obligatorio == ''){
       this.errors.push('Debe indicar si es obligatorio!')
      }

      // valido si la pregunta no esta repetida
      const auxboard = this.$store.state.board.columns[1].campos
     
      const preguntaIndex = auxboard.findIndex(campo =>
        ( campo.idPregunta == this.pregunta && campo.NombreTipo != "Grupo" && campo.NombreTipo !="Item")
      )

      if(preguntaIndex != this.camposIndex && preguntaIndex > -1){
       this.errors.push('La pregunta seleccionada esta en otro campo, No la puede emplear!')
      }

      if( this.errors.length==0){
        return false 
      } else {
        return true 
      }

    },
    Guardar(){

      if( this.validarInfo() ){
        return
      }

      const auxPregunta = this.aPreguntas.filter( preguntas => preguntas.idFD_Pregunta == this.pregunta )

      const auxcampo = {
          id: this.campos.id,
          ancho: this.campos.ancho,
          userAssigned: this.campos.userAssigned,
          idTipoCampo: this.campos.idTipoCampo,
          NombreTipo: this.campos.NombreTipo,
          name: this.campos.name,
          idPregunta: this.pregunta ,
          pregunta: auxPregunta[0].Pregunta,
          obligatorio: this.obligatorio,
          valor: this.campos.valor ,
      }

      this.$store.dispatch('salvarCampoBoard',{camposIndex:this.camposIndex, campo:auxcampo })
      this.$store.dispatch('notificaciones/SetMensaje', "Información almacenada correctamente");
      this.$store.dispatch('notificaciones/SetColor','success');
      this.$store.dispatch('notificaciones/ShowNotificacion', true);
    },
    cargarDatos() {
      this.errors = []
      this.pregunta = this.campos.idPregunta == 0 ? 0 : this.campos.idPregunta
      this.obligatorio = this.campos.obligatorio == '' ? '' : this.campos.obligatorio
    }
  },
  watch: {
    camposIndex(newValue, oldValue) {
      if (newValue !== oldValue){
        this.cargarDatos()
      }
    },
    pregunta(){
      this.getPreguntas()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>