<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <div class="mb-2">
        <span>Pregunta: {{campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')}}</span>
      </div>
        <v-row
          class="mx-1"
          align="center"
          justify="space-around" >
          <v-select
            :items="aValores"
            label="Seleccione Una Opción"
            item-text="valor"
            item-value="id"
            solo
          ></v-select>
      </v-row>

    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"Select",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
      aValores: [{id:0, valor:`Sin Valor 0`}]
    }
  },
  mounted () {
    if(this.aValores.length == 1 && this.aValores[0].id === 0 && this.aValores[0].valor === `Sin Valor 0`){
      this.aValores = []
      for(let i=0; i<5; i++){
        this.aValores.push({id:i, valor:`Sin Valor ${i+1}` })
      }
    }
    if(this.campos.valor.length != 0){
      this.aValores = this.campos.valor
    }
  },
  watch: {
    campos(newCampos) {
      if(newCampos.valor.length != 0){
        this.aValores = newCampos.valor
      }
      
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
