<template>
  <div>
    <v-container fluid >
      <v-row no-gutters>
        <v-col cols="12" class="rounded-5 white pa-2 my-2">
          <h3 class="mb-2 text-medium text-primary font-normal">Descripción:</h3>
          <p class="p-nomargin">
            Campo con el cual usted puede seperar y/o agrupar componentes
          </p>
        </v-col>
        <v-col cols="12" class="white rounded-5 pa-2 my-2">
          <GrupoControl
            v-model="grupo"
            :campos="campos"
            :camposIndex="camposIndex" 
          >  
          </GrupoControl>
        </v-col>
      </v-row>  
      <v-row no-gutters>
        <v-col cols="12" align-end v-if="errors.length" style="font-size: 12px;" class="red--text">
          <p >
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul>
              <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
            </ul>
          </p>
        </v-col>  
        <v-col cols="12" align-end>
          <v-btn block color="btncolor" elevation="2" dark @click="Guardar">
           <span> Guardar </span>
          </v-btn>
        </v-col> 
      </v-row>  
    </v-container>   
  </div>
</template>

<script>
import axiosServices from '@/services/axiosServices.js'
import GrupoControl from '@/components/dragdrop/Campos/GrupoControl.vue'

export default {
  name:"GruposConfig",
  components: {
    GrupoControl,
  },
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    }, 
  },
  data() {
    return {
      grupo  : '',
      aGrupos : [],
      errors: [],
    }
  },
  mounted () {
    this.getGrupos()
    this.cargarDatos()
  },
  methods: {
    async getGrupos() {
      const axiosGrupos = await axiosServices.getConfiguration('buildDinamicos/grupos', true )
      this.aGrupos = axiosGrupos.aData
    },
    validarInfo(){

      this.errors = []

      if(this.grupo == ''){
       this.errors.push('Debe Seleccionar un grupo!')
      }

      // valido si el grupo no esta repetida
      const auxboard = this.$store.state.board.columns[1].campos
     
      const preguntaIndex = auxboard.findIndex(campo =>
        ( campo.idPregunta == this.grupo && campo.NombreTipo == "Grupo" )
      )

      if(preguntaIndex != this.camposIndex && preguntaIndex > -1){
       this.errors.push('La pregunta seleccionada esta en otro campo, No la puede emplear!')
      }

      if( this.errors.length==0){
        return false 
      } else {
        return true 
      }

    },
    Guardar(){

      if( this.validarInfo() ){
        return
      }

      const auxGrupos = this.aGrupos.filter( grupos => grupos.idFD_Grupo == this.grupo )

      const auxcampo = {
          id: this.campos.id,
          ancho: this.campos.ancho,
          userAssigned: this.campos.userAssigned,
          idTipoCampo: this.campos.idTipoCampo,
          NombreTipo: this.campos.NombreTipo,
          name: this.campos.name,
          idPregunta: this.grupo ,
          pregunta: auxGrupos[0].NombreGrupo,
          obligatorio: this.obligatorio,
          valor: this.campos.valor ,
      }

      this.$store.dispatch('salvarCampoBoard',{camposIndex:this.camposIndex, campo:auxcampo })
      this.$store.dispatch('notificaciones/SetMensaje', "Información almacenada correctamente");
      this.$store.dispatch('notificaciones/SetColor','success');
      this.$store.dispatch('notificaciones/ShowNotificacion', true);
    },
    cargarDatos() {
      this.errors = []
      this.grupo = this.campos.idPregunta == 0 ? 0 : this.campos.idPregunta
      this.obligatorio = 0
    }
  },
  watch: {
    camposIndex(newValue, oldValue) {
      if (newValue !== oldValue){
        this.cargarDatos()
      }
    },
    grupo(){
      this.getGrupos()
    }
  },
}
</script>

<style lang="scss" scoped>

</style>