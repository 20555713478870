<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <div class ="text-center titlesdark--text ">
        <h3><span class="font-weight-regular">{{(campos.pregunta=='Sin Pregunta' ? 'SIN NOMBRE GRUPO' : campos.pregunta)}}</span></h3>
      </div>
    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"Grupos",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
