<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <div class="mb-2">
        <span>Pregunta: {{campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')}}</span>
      </div>
      <div class ="text-center titlesdark--text ">
        <h3><span>{{campos.valor.SubForm}}</span></h3>
      </div>
      <div class ="text-center titlesdark--text ">
          <v-icon
            x-large
            color="titlesdark">
            fab fa-wpforms
          </v-icon>
      </div>  
    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"Item",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
