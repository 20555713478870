<template>
  <div>
    <ControlOption
      :campos="campos"
      :camposIndex="camposIndex">
      <div class="my-2">
        <span>Pregunta: {{campos.pregunta + (campos.obligatorio== 1 ? '  **' : '')}}</span>
      </div>
        <v-row
          align="center"
          justify="space-around" >
          <v-btn-toggle v-model="aValores" disabled >
            <v-btn v-for="(valor, key) in aValores" :key="key"
              x-large
              disabled
              depressed
              color="#d7d5d5"
              
            >
              {{valor.valor}}
            </v-btn>
            <!-- color="titlesdark darken-2 "  -->
          </v-btn-toggle>
      </v-row>

    </ControlOption>

  </div>
</template>

<script>
import ControlOption from "@/components/dragdrop/Campos/ControlOption.vue";

export default {
  name:"ToggleButton",
  props: {
    campos: {
      type: Object,
      required: true
    },
    camposIndex: {
      type: Number,
      required: true
    },  
  },
  components: {
    ControlOption,
  },
  data() {
    return {
      aValores: [{id:0, valor:`Sin Valor 0`}]
    }
  },
  mounted () {
    if(this.aValores.length == 1 && this.aValores[0].id === 0 && this.aValores[0].valor === `Sin Valor 0`){
      this.aValores = []
      for(let i=0; i<3; i++){
        this.aValores.push({id:i, valor:`Sin Valor ${i+1}` })
      }
    }
    if(this.campos.valor.length != 0){
      this.aValores = this.campos.valor
    }
  },
  watch: {
    campos(newCampos) {
      if(newCampos.valor.length != 0){
        this.aValores = newCampos.valor
      }
      
    }
  },
    
}
</script>

<style lang="scss" scoped>

</style>
