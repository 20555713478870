<template>
  <div>
    <v-container fluid class="pa-0">
      <v-row no-gutters >
        <v-col cols="12">
          <p> Seleccione el archivo de la imagen a mostrar (Maximo 1MB): </p>
        </v-col>  
      </v-row>
      <v-row no-gutters class="d-flex align-baseline mt-n5">
        <v-col cols="8">
          <v-file-input
            v-model= "aFile" 
            :accept="accept"
            label="click para el archivo"
            prepend-icon="fas fa-file-image"
          ></v-file-input> 

        </v-col>    
        <v-col cols="4">
          <v-btn
          class="ml-5"
            :loading="loading"
            :disabled="loading"
            color="info"
            x-small
            @click="CargarFile"
          >
            <h3>Cargar</h3>
          </v-btn>
        </v-col>    
      </v-row>  
      <v-row no-gutters>
        <v-col cols="12" align-end v-if="errors.length" style="font-size: 12px;" class="red--text">
          <p class="p-nomargin">
            <b>Por favor, corrija el(los) siguiente(s) error(es):</b>
            <ul>
              <li v-for="(error, key) in errors" :key="key">{{ error }}</li>
            </ul>
          </p>
        </v-col>  
      </v-row>  
    </v-container>  
  </div>
</template>

<script>
  import axiosServices from '@/services/axiosServices.js'

  export default {
    name:"UploadFile",
    props: {
      accept: {
        type: String,
        default: "*"
      },
      path :{
        type: String,
        default: ''
      }
    },
    data() {
      return {
        // la estructura de file es  :
        //    lastModified: 1610027770000
        //    lastModifiedDate: Thu Jan 07 2021 08:56:10 GMT-0500 (hora estándar de Colombia) {}
        //    name: "EntregaServiteg_19SIM_24DIC2020.jpeg"
        //    size: 55173
        //    type: "image/jpeg"
        //    webkitRelativePath: ""
        aFile: [],
        loading: false,
        errors: [],
      }
    },
    methods: {
      async CargarFile() {
        this.loading = true
        if(this.ValidarErrores()){
          const filename = await this.SaveFile()
          this.$emit('input',filename)
        }
          this.loading = false  
      },
      ValidarErrores() {
        const maxSize = 1000000;  //Maximo tamño 1 MB
        if (this.aFile.length !== 0) {
          if (this.aFile.size > maxSize){
            this.errors.push('Archivo muy grande, maximo 1 MB!')
            return false
          }else {
            this.errors = []
            return true
          }
        } else {
          this.errors.push('Debe Seleccionar un archivo!')
          return false
        }
      },
      async SaveFile (){
        // el archivo se guarda en la ruta que se le indique en el props path
          let formData = new FormData();
          formData.append('file', this.aFile);
          formData.append('ruta', this.path);
          const aData = await axiosServices.postConfiguration('/savefile', formData, true)
          return aData.aData
        
      }
    },
    watch: {
      aFile() {
        this.errors = []
      }
    },
    
  }
</script>

<style lang="scss" scoped>

</style>